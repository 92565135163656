  // Async get Colors List
  function getColors(callback) {
    $.get('/api/color', {
        paper_type: $('#paper_type').val()
      })
      .done(function(data) {
        populate(data, '#paper_color');
        callback();
      });
  }

  // Async get Flat Sizes List
  function getFlatSizes(callback) {
    $.get('/api/flat-size', {
        paper_type: $('#paper_type').val()
      })
      .done(function(data) {
        populate(data, '#flat_size');
        callback();
      });
  }

  // Async get Finished Sizes List
  function getFinishedSizes(callback) {
    $.get('/api/finished-size', {
        flat_size: $('#flat_size').val()
      })
      .done(function(data) {
        populate(data, '#finished_size');
        callback();
      });
  }

  // Async get Bindings List
  function getBindings(callback) {
    $.get('/api/binding', {
        flat_size: $('#flat_size').val(),
        finished_size: $('#finished_size').val(),
      })
      .done(function(data) {
        populate(data, '#binding');
        callback();
      });
  }


  function checkFlatSize(callback) {
    if ($('#flat_size').val() > 0) {
      getFinishedSizes(function() {
        if (callback && typeof(callback) === "function") {
          callback();
        }
      });
    } else {
      $('#finished_size').empty();
    }
    $('#binding').empty();
  }

  function checkPaperType(callback) {
    if ($('#paper_type').val() > 0) {
      getColors(function() {
        getFlatSizes(function() {
          if (callback && typeof(callback) === "function") {
            callback();
          }
        });
      });

      if (['6', '8', '9', '10'].indexOf($('#paper_type').val()) > -1) {
        $('#ink1').prop('disabled', true);
        $('#ink2').prop('checked', true);
      } else {
        $('#ink1').prop('disabled', false);
      }

    } else {
      $('#paper_color').empty();
      $('#flat_size').empty();
    }
    $('#finished_size').empty();
    $('#binding').empty();
  }


  function checkFinishedSize(callback) {
    if ($('#finished_size').val() > 0) {
      getBindings(function() {
        if (callback && typeof(callback) === "function") {
          callback();
        }
      });
    } else {
      $('#binding').empty();
    }
  }








  // Populate a select list.
  function populate(data, e) {
    var d = $(e);
    var addAttribute = '';
    d.empty();
    // Auto select option when there is only one
    if (data.length > 1) {
      addAttribute =  'selected';
      d.addClass('please-select');
    }
    d.append('<option disabled ' + addAttribute + '>Please select</option>');
    $.each(data, function(index, element) {
      d.append('<option value="' + element.id + '">' + element.name + '</option>');
    });
    data.length <= 1 && d.val(d.find('option').eq(1).val()).change();
  }


  jQuery(document).ready(function($) {

    checkPaperType();

    $('#paper_type').change(function() {
      checkPaperType();
    });

    $('#flat_size').change(function() {
      checkFlatSize();
    });

    $('#finished_size').change(function() {
      checkFinishedSize();
    });







  });
