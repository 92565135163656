jQuery(document).ready(function($) {
    // Form submitted
    $('form.create, form.edit').submit(function(event) {
        submitRequest = true;
        $('input, select').keydown(function(e) {
            console.log(e.keyCode);
            if (e.keyCode == 13 || e.keyCode == 10) {
                console.log('Yay');
                submitRequest = false;
                // return false;
            }
        });

        event.preventDefault();

        // Clear previous errors
        $('body').removeClass('haserrors');
        $('.form-group').removeClass('errors');
        $('.input-error').remove();

        var form = $(this);

        if (submitRequest) {
            // Send request
            $.ajax({
                url: form.attr('action'),
                type: 'post',
                data: form.serialize(),
                dataType: 'json',
            })

                // Success
                .done(function(data) {
                    // console.log('##Done##');
                    $('body').addClass('success');

                    var h1, successMsg;
                    if ($('body').hasClass('project-create')) {
                        h1 = 'Success!';
                        successMsg = 'Your New Number Is';
                        $('#success .mkg').text(data['mkg']);
                    } else if ($('body').hasClass('project-edit')) {
                        h1 = 'Success!';
                        successMsg = 'Project successfully updated';
                    }

                    $('h1').text(h1);
                    $('#success .msg').text(successMsg);

                    $('.select-search-projects, .required-note').hide();

                    form.animate(
                        {
                            height: 0,
                            opacity: 0,
                        },
                        500,
                        function() {
                            $('#success')
                                .delay(100)
                                .addClass('show');
                        }
                    );
                })

                // Errors
                .fail(function(data) {
                    var errors = data.responseJSON.errors;

                    $('.datepicker').datepicker();
                    $('.form-group').removeClass('error');
                    // Place errors above their respective inputs / labels
                    $.each(errors, function(k, v) {
                        e = '<ul>';
                        $.each(v, function(i, v) {
                            e += '<li>' + v + '</li>';
                        });
                        e += '</ul>';
                        $('[for^=' + k + ']').before('<div class="input-error">' + e + '</div>');
                        $('[for^=' + k + ']')
                            .parent('.form-group')
                            .addClass('error');
                    });

                    // Scroll to first error
                    var errorDiv;
                    if ((errorDiv = $('.input-error').first())) {
                        $('html,body').animate(
                            {
                                scrollTop: errorDiv.offset().top,
                            },
                            200,
                            function() {
                                $('body').addClass('haserrors');
                            }
                        );
                    }
                }); // end ajax
        }
    }); // end form submit

    $('[required=required]').each(function() {
        inputId = $(this).attr('id');
        $('[for=' + inputId + ']').addClass('input-required');
    });
});
